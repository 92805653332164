import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
            @font-face {
                font-family: 'Inter';
                src: url('/fonts/Inter-Regular.woff2') format('woff2');
                font-weight: 400;
                font-style: normal;
                font-display: swap;
            }
            @font-face {
                font-family: 'Inter';
                src: url('/fonts/Inter-Bold.woff2') format('woff2');
                font-weight: bold;
                font-style: normal;
                font-display: swap;
            }
        `}
  />
);

export default Fonts;
