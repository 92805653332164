import {
  createStore, action, Action, createTypedHooks,
} from 'easy-peasy';

const {
  useStoreActions, useStoreState, useStoreDispatch, useStore,
} = createTypedHooks<IStoreModel>();

interface IStoreModel {
  customerId: number | null;
  updateProfileId: Action<IStoreModel, number | null>
}

const store = createStore<IStoreModel>({
  customerId: null,
  updateProfileId: action((state, payload) => {
    state.customerId = payload;
  }),
});

export {
  store,
  useStoreActions,
  useStoreState,
  useStoreDispatch,
  useStore,
};
