const Switch = {
  baseStyle: {
    track: {
      bg: 'brandScheme.100',
      _checked: {
        bg: 'brand',
      },
    },
  },
};
export default Switch;
