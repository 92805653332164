import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useStoreActions } from '../../store';
import { useAsync } from '../../hooks/useAsync';
import {
  getAuthToken, setAuthToken, deleteAuthToken, parseAuthToken,
} from './token';

const apiURL = process.env.REACT_APP_API_URL;
const userKey = process.env.REACT_APP_USER_STORAGE_KEY!;

interface ILoginProps {
  email: string,
  password: string,
}

interface IUser {
  name: string,
  displayName: string,
  email: string,
  language: string,
  token: string,
  tokenExpiration: string,
  tokenExpiry: number
  role: Array<string>;
}

interface IAuthProviderProps {
  children: React.ReactChild,
}

type ContextProps = {
  authenticated: boolean,
  lang: string,
  theme: string,
  user: IUser,
  // eslint-disable-next-line no-unused-vars
  login: (loginData : ILoginProps) => void,
  logout: () => void
};

// return user data from token or API call
async function bootstrapAppData() {
  const token = await getAuthToken();
  if (!token) return null;
  // get user info from jwt token
  const jwtUser = parseAuthToken();
  return jwtUser;
}

const AuthContext = React.createContext<Partial<ContextProps>>({});
AuthContext.displayName = 'AuthContext';

function loginFn({ email, password } : ILoginProps) {
  const authRequest = async () => {
    const config = {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: { 'Content-Type': 'application/json' },
    };
    return window.fetch(`${apiURL}/user/business/auth/token`, config).then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      }
      return Promise.reject(data);
    });
  };
  return authRequest().then((response) => {
    const jwtUser = parseAuthToken(response.token);
    setAuthToken(response.token);
    return { ...response, ...jwtUser };
  });
}

function LogoutFn() {
  deleteAuthToken();
  return true;
}

function AuthProvider(props: IAuthProviderProps) {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync<IUser, Error>();
  // eslint-disable-next-line no-unused-vars
  const updateProfileId = useStoreActions((actions) => actions.updateProfileId);
  // const profileId = useStoreState((state) => state.customerId);
  // eslint-disable-next-line no-unused-vars
  const queryClient = useQueryClient();

  // get user data on app mount ------------------
  React.useEffect(() => {
    const appDataPromise = bootstrapAppData();
    run(appDataPromise);
  }, [run]);

  const login = React.useCallback(
    (formData) => loginFn(formData).then((userData) => {
      setData(userData);
      return userData;
    }), [setData],
  );

  const logout = React.useCallback(() => {
    updateProfileId(null);
    // queryClient.invalidateQueries();
    LogoutFn();
    setData(null);
    if (userKey) window.localStorage.removeItem(userKey);
    window.location.reload();
  }, [setData]);

  const value = React.useMemo(
    () => ({ user, login, logout }),
    [login, logout, user],
  );

  if (isLoading || isIdle) {
    return <h1>Loading...</h1>;
  }

  if (isError) {
    return <h1>{error}</h1>;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
