const heading = {
  // 1. We can update the base styles
  baseStyle: {},
  // 2. We can add a new button size or extend existing
  sizes: {
    lg: {
      fontSize: '32px',
    },
  },
  // 3. We can add a new visual variant
  variants: {},
};

export default heading;
