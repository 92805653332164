// theme.js
import { extendTheme } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';
// Component style overrides
import Button from './components/button';
import Heading from './components/heading';
import Switch from './components/switch';
import {
  Input, Checkbox, Radio,
} from './components/input';

const themeColors = {
  background: '#fff',
  text: '#333333',
  titles: '#333333',
  contrast: '#fff',
  link: '#333333',
  hover: '#4f4f4f',
  brand: '#333333',
  accent: '#db817f',
  success: '',
  error: '',
  brandScheme: {
    25: '#F3F3F3',
    50: '#F2F2F2',
    100: '#DBDBDB',
    200: '#C4C4C4',
    300: '#ADADAD',
    350: '#828282',
    400: '#969696',
    500: '#4f4f4f',
    600: '#333333',
    700: '#333333',
    800: '#242424',
    900: '#1A1A1A',
    1000: '#000000',
  },
};

const {
  background, brand, accent, text, link, hover, contrast, brandScheme,
} = themeColors;
const overrides = {
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: background,
        color: text,
        lineHeight: '1.4rem',
      },
      // styles for the `a`
      a: {
        color: link,
        // fontWeight: '500',
        display: 'inline-block',
        _hover: {
          color: hover,
          // textDecoration: 'none',
        },
      },
      select: {
        height: '2.9em !important',
        borderRadius: '6px !important',
      },
    },
  },

  colors: {
    brand,
    accent,
    text,
    contrast,
    brandScheme,
    'brand.light': tinycolor(brand).brighten(20).desaturate().toString(),
    'brand.dark': tinycolor(brand).darken(10).toString(),
  },
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
  components: {
    Checkbox,
    Radio,
    Button,
    Heading,
    Input,
    Switch,
  },
};

export default themeColors;
export const customTheme = extendTheme(overrides);
