import { Spinner, Flex } from '@chakra-ui/react';

export default function CustomSpinner() {
  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      position="absolute"
      top="-15px"
      right="0"
      bottom="0"
      left="0"
      zIndex="200"
      className="spinner"
      bg="white"
    >
      <Spinner size="xl" />
    </Flex>
  );
}
