import React from 'react';
import { render } from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './store/index';
import { AuthProvider } from './lib/auth/auth-provider';
import { customTheme } from './theme/index';
import Fonts from './theme/fonts';
import App from './app';

import './i18n';

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

export const queryClient = new QueryClient({
  defaultOptions: {
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
  },
});

function Tailoor() {
  return (
    <ChakraProvider theme={customTheme}>
      <Fonts />
      <QueryClientProvider client={queryClient}>
        <StoreProvider store={store}>
          <AuthProvider>
            <Router>
              <App />
            </Router>
          </AuthProvider>
        </StoreProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  );
}

const rootElement = document.getElementById('root');
render(<Tailoor />, rootElement);
