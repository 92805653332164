const button = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: '600',
    borderRadius: '.3rem',
  },
  // 2. We can add a new button size or extend existing
  sizes: {
  },
  // 3. We can add a new visual variant
  variants: {
    solid: () => ({
      bg: 'brand',
      color: 'contrast',
      p: '1.5em 4em',
      _hover: {
        bg: 'brand.dark',
        color: 'accent',
        _disabled: {
          bg: 'brand',
          color: 'contrast',
        },
      },

    }),
    // primary: () => ({
    //     bg: '#005870',
    //     color: '#fff',
    //     _hover: {
    //         bg: '#173037',
    //     },
    // }),
    outline: () => ({
      bg: 'transparent',
      p: '1.5em 4em',
      color: 'brand',
      borderColor: 'brand',
      _hover: {
        color: 'accent',
        borderColor: 'accent',
      },
    }),
  },
};

export default button;
