const Input = {
  parts: ['field'],
  baseStyle: {
    field: {
      py: '1.2em',
    },
  },
  sizes: {},
  variants: {},
};

const Checkbox = {
  defaultProps: {
    colorScheme: 'brandScheme',
  },
};
const Radio = {
  defaultProps: {
    colorScheme: 'brandScheme',
  },
};

export {
  Input, Checkbox, Radio,
};
