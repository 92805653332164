import React from 'react';

const defaultInitialState = { status: 'idle', data: null, error: null };

interface IDefaultState<TData, TError> {
  status: string,
  data: TData,
  error: TError
}

// safe dispatch, see => https://headbutt.io/useSafeDispatch/
function useSafeDispatch(dispatch: React.Dispatch<any>) {
  const mounted = React.useRef(false);
  // @ts-ignore
  React.useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);
  return React.useCallback(
    // @ts-ignore
    (...args) => (mounted.current ? dispatch(...args) : undefined),
    [dispatch],
  );
}

function useAsync<TData, TError>(initialState?: IDefaultState<TData, TError>) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  });
  const [{ status, data, error }, setState] = React.useReducer(
    (s: IDefaultState<TData, TError>, a: React.SetStateAction<any>) => ({ ...s, ...a }),
    initialStateRef.current,
  );

  const safeSetState = useSafeDispatch(setState);

  const setData = React.useCallback(
    (dataObj) => safeSetState({ data: dataObj, status: 'resolved' }),
    [safeSetState],
  );
  const setError = React.useCallback(
    (errorObj) => safeSetState({ error: errorObj, status: 'rejected' }),
    [safeSetState],
  );
  const reset = React.useCallback(
    () => safeSetState(initialStateRef.current),
    [safeSetState],
  );

  const run = React.useCallback(
    <TDataObj, TErrorObj>(promise: Promise<TDataObj> | void) => {
      if (!promise || !promise.then) {
        throw new Error(
          'The argument passed to useAsync().run must be a promise. Maybe a function that\'s passed isn\'t returning anything?',
        );
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        (dataObj: TDataObj) => {
          setData(dataObj);
          return dataObj;
        },
        (errorObj: TErrorObj) => {
          setError(errorObj);
          return Promise.reject(errorObj);
        },
      );
    },
    [safeSetState, setData, setError],
  );

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',
    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

export { useAsync };
